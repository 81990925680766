<template>
  <section class="two-pane-vertical" style="margin: 10px">
    <div class="two-pane-horizontal">
      <div class="content-left" style="padding: 5px">
        <ul>
          <li>{{ loinfo.footer_address_name }}</li>
          <li>
            <a
              :href="loinfo.footer_address_nmls_link"
              style="text-decoration: underline"
              >{{ loinfo.footer_address_nmls_text }}</a
            >
          </li>
          <li>{{ loinfo.footer_address_street }}</li>
          <li>{{ loinfo.footer_address_city_zip }}</li>
          <li>
            <a :href="loinfo.linkedin_link">
              <img
                v-if="loinfo.linkedin_link"
                src="..\assets\LinkedInLogoSVG.svg"
              />
            </a>
            <a :href="loinfo.zillow_link">
              <img v-if="loinfo.zillow_link" src="..\assets\ZillowLogo.jpg" />
            </a>
            <a :href="loinfo.facebook_link">
              <img
                v-if="loinfo.facebook_link"
                src="..\assets\FaceBookLogo.jpg"
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="content-right" style="padding: 5px">
        <ul>
          <li>
            <a @click="onLinkSelect">Terms of Use</a>
          </li>
          <li>
            <a @click="onLinkSelect">Consumer Privacy Policy</a>
          </li>
          <li>
            <a @click="onLinkSelect">Website Privacy Policy</a>
          </li>
          <li>
            <a @click="onLinkSelect">Company State Licenses</a>
          </li>
          <li>
            <a @click="onLinkSelect">CCPA Privacy Policy</a>
          </li>
          <li>
            <a @click="onLinkSelect">Do Not Sell My Information</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="two-pane-horizontal horizontal">
      <img
        id="EqualHousing"
        alt="Equal Housing Logo"
        src="https://asset-service-bucket-prod.s3.amazonaws.com/637bb1c1-b029-4301-95d4-b4b3a63ff517"
        class="equal-housing-logo"
        style="border-radius: 0; height: 50px; width: auto"
      />
      <a
        target="_blank"
        :href="loinfo.footer_goldstar_link"
        aria-label="Link to Gold Star Mortgage Corporate Website"
        class="footer-goldstar-link"
        tabindex="0"
      >
        www.goldstarfinancial.com
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    loinfo: Object,
  },
  methods: {
    onLinkSelect(e) {
      const linkText = e.target.innerHTML;
      if (linkText === "Terms of Use") {
        window.location.href =
          window.location.origin +
          "/info?lar=" +
          this.loinfo.lar +
          "&infopage=terms-of-use";
      } else if (linkText === "Consumer Privacy Policy") {
        window.location.href =
          window.location.origin +
          "/info?lar=" +
          this.loinfo.lar +
          "&infopage=consumer-privacy";
      } else if (linkText === "Website Privacy Policy") {
        window.location.href =
          window.location.origin +
          "/info?lar=" +
          this.loinfo.lar +
          "&infopage=website-privacy";
      } else if (linkText === "Company State Licenses") {
        window.location.href =
          window.location.origin +
          "/info?lar=" +
          this.loinfo.lar +
          "&infopage=company-licenses";
      } else if (linkText === "CCPA Privacy Policy") {
        window.location.href =
          window.location.origin +
          "/info?lar=" +
          this.loinfo.lar +
          "&infopage=ccpa-privacy";
      } else if (linkText === "Do Not Sell My Information") {
        window.location.href =
          window.location.origin +
          "/info?lar=" +
          this.loinfo.lar +
          "&infopage=do-not-sell";
      }
    },
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  --dark-blue: #051d3a;
  --light-blue: #094355;
  --teal: rgba(0, 178, 164);
  --font-main: rgb(75, 74, 74);
  padding-top: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

a {
  text-decoration: none;
  color: var(--font-main);
}

a:hover {
  text-decoration: underline;
}

li {
  font-size: 1rem;
  display: block;
  line-height: 1.3rem;
  margin-bottom: 15px;
}

li a {
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
}

.two-pane {
  box-sizing: border-box;
  flex: auto;
  height: auto;
  display: flex;
  flex-direction: row;
}

.two-pane-vertical {
  font-size: 1.3rem;
  border-radius: 3px;
  height: auto;
  padding: 10px;
  margin: 40px;
  display: flex;
  flex: auto;
  background: white;
  flex-direction: column;
}

.two-pane-vertical img {
  border-radius: 3px;
  height: auto;
  width: 70%;
  object-fit: contain;
}

.two-pane-horizontal {
  display: flex;
  flex-direction: row;
}

.left-pane {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.right-pane {
  margin-left: 20px;
}

.text-center p,
.text-center h2 {
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--font-main);
}

.content-left {
  flex: auto;
  color: var(--font-main);
  text-align: left;
  padding: 40px;
  line-height: 40px;
}

.content-left p {
  font-size: 1.3rem;
}

.content-left h1 {
  font-size: 2rem;
}

.content-left img,
.fa-linkedin,
.fa-square-facebook {
  border-radius: 0;
  height: 40px;
  width: 40px;
  font-size: 40px;
}

.content-right {
  flex: auto;
  color: var(--font-main);
  text-align: right;
  padding: 40px;
  line-height: 40px;
  align-items: right;
}

.one-pane {
  padding: 10px;
  height: auto;
  background: white;
}

.one-pane h1 {
  font-weight: bold;
  padding: 10px;
  font-size: 3rem;
}

.one-pane img {
  border-radius: 0;
  height: 50px;
  width: auto;
}

.horizontal {
  display: flex;
  justify-content: space-between;
}

.horizontal > * {
  width: auto;
  margin: 0 10px;
}

.slide {
  width: 100vw;
  position: relative;
  bottom: 40px;
}

@media (max-width: 1000px) {
  .two-pane {
    box-sizing: border-box;
    flex: auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .left-pane,
  .right-pane {
    margin: 40px;
  }
}

@media (max-width: 690px) {
  .body {
    padding-top: 122px;
    padding-bottom: 0;
  }
}

@media (max-width: 713px) {
  .slide {
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 430px) {
  .content-left {
    padding: 0;
  }

  .two-pane-vertical {
    margin: 40px 20px;
  }

  .right-pane {
    padding: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 375px) {
  .two-pane-vertical {
    font-size: 1rem;
  }
}
</style>
